<template>
  <nav class="navbar sticky-top">
    <div class="container">
      <div class="logo">
        <img src="@/assets/images/logo_trakken.svg" alt="Trakken"> <span>Video Training</span>
      </div>
      <div v-if="isAuthenticated" class="logout">
        <a href="#" @click.prevent="logout">Logout</a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
		user() {
			return this.$store.state.firebaseUser
		},
    isAuthenticated() {
      return this.$store.state.isAuthenticated
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('signOut');
      this.$router.replace("/login")
    }
  },
  mounted() {
    this.$store.dispatch('getUser');
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  font: normal normal 600 14px/18px Mulish;
  color: #323438;
  background: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #D9D9D9;

  .logo span {
    vertical-align: middle;
    margin-left: 10px;
  }

  .logout {
    a {
      font: normal normal 600 14px/18px Mulish;
      color: #323438;
      text-decoration: none;
    }
  }
}
</style>
