<template>
  <section class="contact text-center container">
    <div class="row">
      <div class="col">
        <h2 class="h2">If you need our help, please contact us.</h2>
        <p>Don’t hesitate to contact us regarding the products and our services.</p>
        <a href="mailto:ga4@trakken.org" class="btn btn-outline-primary" target="_blank">Contact us</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact', 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.contact {
  padding-top: 50px;  
  padding-bottom: 50px;  
  color: #323438;

  .h2{
    font: normal normal bold 24px/30px Mulish;
    margin-bottom: 10px;
  }
  p {
    font: normal normal normal 16px/24px Mulish;
    margin-bottom: 30px;
  }
  .btn {
    font: normal normal normal 16px/24px Mulish;
    color: #DB0000;
    border: 2px solid #DB0000;
    border-radius: 5px;
    padding-left: 45px;
    padding-right: 45px;

    &:hover{
      color: #fff;
      background-color: #DB0000;
      border-color: #DB0000;
    }
  }

}
</style>
