<template>
  <section class="waves">
  </section>
</template>

<script>
export default {
  name: 'Waves', 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.waves {
  height: 225px;
  margin-top: 100px;
  background: transparent url('~@/assets/images/pattern-waves.svg') 0 0 / auto 225px repeat-x;
}
</style>
