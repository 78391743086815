<template>
  <header class="header">
    <div class="hero-image"></div>
    <div class="hero-inner text-center">
      <h1 class="h1">New Google Analytics</h1>
      <p>Video Trainings by Trakken</p>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  position: relative;
  min-height: 440px;
  // height: 100vh;
  height: 440px;
  color: #ffffff;
}
.hero-image {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.13) 0%, rgba(0, 0, 0, 0.13) 50%),
    url('~@/assets/images/trakken_training_video_background_2.jpg');
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  .h1 {
    font: normal normal bold 40px/50px Mulish;
    @media (min-width: 768px) {
      font: normal normal bold 60px/75px Mulish;
    }
  }
  p {
    font: normal normal normal 20px/30px Mulish;
  }
}
</style>
