<template>
  <section class="container videos">
    <div v-if="loading" class="d-flex justify-content-center">
      <div class="spinner-border m-5" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <article class="video" v-for="(video, videoIndex) in videos" :key="video.title">
      <div class="row">
        <div class="col-md-10 offset-md-1 offset-lg-0 col-lg-6 mb-3 mb-lg-0">
          <a class="btn thumbnail" @click="openVideo(videoIndex)">
            <img :src="video.thumbnail" />
          </a>
        </div>
        <div class="col-md-10 col-lg-5 offset-md-1 d-flex flex-column justify-content-center">
          <p class="topic mb-2">{{ video.topic }}</p>
          <h2 class="h2 mb-3 mb-lg-4">{{ video.title }}</h2>
          <p>{{ video.text }}</p>
          <a class="watch" @click="openVideo(videoIndex)">Watch Training</a>
        </div>
      </div>
    </article>
    <div class="modal fade" ref="videoOverlay" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close btn-close-white" @click="closeVideo()" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="currentVideo.isYoutube" class="ratio ratio-16x9">
              <iframe :src="currentVideo.src + youtubeParameters" :title="currentVideo.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ref="youtube"></iframe>
            </div>
            <video v-if="!currentVideo.isYoutube" controls ref="video">
              <source :src="currentVideo.src"
                type="video/mp4">
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Modal } from 'bootstrap'
export default {
  name: 'VideoList',
  data() {
    return {
      overlay: null,
      currentVideo: {
        src: null,
        title: null,
        isYoutube: false
      },
      youtubeParameters: '?controls=2&modestbranding=1&showinfo=0&rel=0&autoplay=1'
    }
  },
  computed: {
		videos() {
			return this.$store.state.videos
		},
    loading() {
			return this.$store.state.loading
		},
	},
  methods: {
    openVideo(index) {
      this.currentVideo = {
        src: this.videos[index].video,
        title: this.videos[index].title,
        isYoutube: this.videos[index].video.includes('youtube'),
      }
      this.overlay.show();
      if (!this.currentVideo.isYoutube) {
        this.$refs.video.load();
        this.$refs.video.play();
      }
    },
    closeVideo() {
      this.overlay.hide();
    },
    clearVideo() {
      if (!this.currentVideo.isYoutube) {
        this.$refs.video.pause();
      }
      this.currentVideo = {
        src: null,
        title: null,
        isYoutube: false
      };
    }
  },
  mounted() {
		this.$store.dispatch('getVideos');
    this.overlay = new Modal(this.$refs.videoOverlay);
    this.$refs.videoOverlay.addEventListener("hidden.bs.modal", this.clearVideo);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.spinner-border {
  color: #db0000;
}
.video {
  margin-top: 100px;
  .topic {
    font: normal normal bold 16px/20px Mulish;
  }
  .h2 {
    font: normal normal bold 30px/38px Mulish;
    color: #323438;
  }
  p {
    font: normal normal normal 16px/24px Mulish;
  }
  a {
    font: normal normal 600 15px/24px Mulish;
    color: #db0000;
    text-decoration: none;

    &.thumbnail{
      display: block;
      position: relative;
      padding: 0;
      border: 0;

      &:after {
        content: "";
        height: 120px;
        width: 120px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: transparent url('~@/assets/images/icon_play.svg') 0% 0% no-repeat;
        opacity: 0;
        transition: opacity .3s ease;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
      
      img {
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgb(0 0 0 / 16%);
      }
    }

    &.watch{
      display: inline-block;
      cursor: pointer;
      &:after{
        content: "";
        display: inline-block;
        width: 12px;
        height: 9px;
        background: transparent url('~@/assets/images/icon_arrow-right.svg') 0% 0% / 12px 9px no-repeat;
        margin-left: 4px;
        transform: translate(0);
        transition: transform .3s ease;
      }
      &:hover{
        &:after{
          transform: translate(5px);
        }
      }
    }
  }
}
.modal {
  .modal-header {
    border: 0;
  }
  .modal-content {
    background: transparent;
    border: 0;
    border-radius: 0;
  }
  video {
    width: 100%
  }
}
</style>

<style lang="scss">
.modal-backdrop.show {
  opacity: 0.8 !important;
}
</style>