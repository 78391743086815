<template>
  <div>
    <NavBar />
    <Header />
    <router-view />
    <Waves />
    <Contact />
    <Footer />  
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Header from './components/Header.vue'
import Waves from './components/Waves.vue'
import Contact from './components/Contact.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Header,
    Waves,
    Contact,
    Footer
  },
}
</script>

<style>
#app {
  font-family: "Mulish";
  background: #ffffff;
  color: #74797f;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
