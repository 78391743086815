import { createRouter, createWebHistory } from 'vue-router'
import VideoList from './components/VideoList.vue'
import Login from './components/Login.vue'
import { getAuth } from 'firebase/auth'

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: VideoList,
      meta: {
        requiresAuth: true
      }
		},
    {
      path: '/login',
      name: 'login',
      component: Login
    }
	],
})

router.beforeEach((to) => {
  const isAuthenticationRequired = to.meta.requiresAuth
  const auth = getAuth()
  const isAuthenticated = !!auth.currentUser
  if (isAuthenticationRequired && !isAuthenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/login',
    }
  }
})
