import { createStore } from "vuex";
import axios from "axios";
import { getAuth, signOut } from 'firebase/auth';

export const store = createStore({
  state: {
    loading: true,
    videos: {},
    firebaseUser: { 
			email: null, 
			photoURL: null 
		},
		isAuthenticated: false
  },
  mutations: {
    setLoadingState(state, data) {
      state.loading = data.loading;
    },
    setVideos(state, videos) {
      state.videos = videos;
    },
    setFirebaseUser(state, user) {
      state.firebaseUser = user;
    },
		setAuthenticationStatus(state, status) {
			state.isAuthenticated = status
		}
  },
  actions: {
    getVideos(context) {
      var domain = "/api/v1/videos";
      axios
        .get(domain)
        .then((res) => {
          context.commit("setVideos", res.data);
          context.commit("setLoadingState", { loading: false });
        })
        .catch((err) => {
          context.commit("setLoadingState", { loading: false });
          console.warn(err);
        });
    },
		getUser(context) {
			const auth = getAuth()
			const user = auth.currentUser
			context.commit("setFirebaseUser", user)
			context.commit("setAuthenticationStatus", !!user)
		},
		signOut(context) {
			const auth = getAuth();
      signOut(auth)
        .then(() => {					
					context.commit("setFirebaseUser", { email: null, photoURL: null })
					context.commit("setAuthenticationStatus", false)
        })
        .catch((error) => {
          console.error(error.message);
        });
		}
  }
});
