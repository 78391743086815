import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { router } from './router'
import { store } from './store'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './scss/app.scss'
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_DOMAIN,
};
initializeApp(firebaseConfig);

let app
const auth = getAuth()
onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App)

    app.use(VueAxios, axios)
    app.use(router)
    app.use(store)

    app.mount('#app')
  }
})
