<template>
  <section class="container login">
    <div class="modal fade" ref="loginOverlay" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h2 class="h2">Trakken Video Training</h2>
          </div>
          <div class="modal-body justify-content-center">
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <form action="#" @submit.prevent="submit">
              <!-- <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

                <div class="col-md-6">
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    name="email"
                    value
                    required
                    autofocus
                    v-model="form.email"
                  />
                </div>
              </div> -->

              <div class="form-group row my-4 justify-content-center">
                <div class="col-md-6">
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    name="password"
                    placeholder="Password" 
                    required
                    v-model="form.password"
                  />
                </div>
              </div>

              <div class="form-group row my-4 justify-content-center">
                <div class="col-md-4 text-center">
                  <button type="submit" class="btn btn-block">Login</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Modal } from 'bootstrap'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
export default {
  name: 'Login',
  data() {
    return {
      overlay: null,
      form: {
        // email: "",
        password: ""
      },
      error: null
    }
  },
  computed: {
	},
  methods: {
    submit() {
      this.error = null
      const auth = getAuth();
      // signInWithEmailAndPassword(auth, this.form.email, this.form.password)
      signInWithEmailAndPassword(auth, 'trainings@trkkn.com', this.form.password)
        .then(() => {
          this.overlay.hide();
          this.$router.push({ name: 'home' })
          this.$store.dispatch('getUser');
        })
        .catch((error) => {
          this.error = error.message
          // console.error(error);
        });
    }
  },
  mounted() {
    this.overlay = new Modal(this.$refs.loginOverlay);
    this.overlay.show();
		
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-header {
  border-bottom: 0;
}
.h2 {
  font: normal normal bold 30px/38px Mulish;
  color: #323438;
}
.form-control {
  border-radius: 0;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #323438;
}
.btn {
  font: normal normal normal 16px/24px Mulish;
  color: #DB0000;
  border: 2px solid #DB0000;
  border-radius: 5px;
  padding-left: 45px;
  padding-right: 45px;

  &:hover{
    color: #fff;
    background-color: #DB0000;
    border-color: #DB0000;
  }
}
</style>

<style lang="scss">
.modal-backdrop.show {
  opacity: 0.8 !important;
}
</style>