<template>
  <footer class="footer">
    <div class="top">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-md-4 col-lg-6">
            <p>
              <span class="logo"></span>
              Trakken GmbH<br/>
              <a href="https://www.trkkn.com" target="_blank">www.trkkn.com</a></p>
          </div>
          <div class="col-12 col-md-8 col-lg-6 text-start text-md-end">
            <img src="@/assets/images/gmp_badge@2x.png" width="200" height="90" />
            <img src="@/assets/images/gcp_badge@2x.png" width="170" height="90" />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-md-6 text-center text-md-end mb-3 mb-md-0">
            <a href="https://www.trakken.de/impressum" target="_blank">Imprint</a>
            <a href="https://www.trakken.de/datenschutz" target="_blank">Data Privacy</a>
          </div>
          <div class="col-12 col-md-6 text-center text-md-start order-md-first">
            <p>Copyright &copy; {{ new Date().getFullYear() }} Trakken</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer', 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  background: #2A2D31;
  color: #74797f;
  font: normal normal normal 14px/18px Mulish;

  a {
    color: #FFFFFF;
    text-decoration: none;
  }
  p {
    margin-bottom: 0;
  }

  .top {
    background: #323438;
    color: #FFFFFF;
    padding: 20px 0;

    .logo{
      display: block;
      width: 133px;
      height: 20px;
      background: transparent url('~@/assets/images/logo_trakken_white.svg') 0% 0% no-repeat;
      margin-bottom: 10px;
    }
  }
  .bottom {
    padding: 20px 0;

    a{
      color: #74797F;
      display: inline-block;
      transition: color .3s ease;
      &:hover{
        color: #fff
      }
      + a {
        margin-left: 10px;
      }
    }
  }

}
</style>
